import React from "react";
import { Link, Route, Routes } from "react-router-dom";
import Page from "../Styles/Page.Styled";
import Complex from "./Partials/Types/Complex";
import Moderat from "./Partials/Types/Moderat";
import Simply from "./Partials/Types/Simply";
import styled from "styled-components";
import Transitions from "../Styles/Transition";

const OffersNav = styled.nav`
  ul {
    display: flex;
    gap: 3em;
    li {
      list-style: none;
      a {
        font-size: 1.5em;
        text-decoration: none;
      }
    }
  }
`;

const Offers = () => {
  // const options = [
  //   { value: "Simpel side", label: "Simpel side" },
  //   { value: "Moderat side", label: "Moderat side" },
  //   { value: "Kompleks side", label: "Kompleks side" },
  // ];

  return (
    <Transitions>
      <Page>
        <header>
          <h3>Priser</h3>
        </header>
        {/* <CheckboxForm options={options} /> */}
        <OffersNav>
          <ul>
            <li>
              <Link to="/offers/simple">Simpel side</Link>
            </li>
            <li>
              <Link to="/offers/moderat">Moderat side</Link>
            </li>
            <li>
              <Link to="/offers/complex">Kompleks side</Link>
            </li>
          </ul>
        </OffersNav>

        <Routes>
          <Route path="/offers/simple" element={<Simply />} />
          <Route path="/offers/moderat" element={<Moderat />} />
          <Route path="/offers/complex" element={<Complex />} />
        </Routes>
      </Page>
    </Transitions>
  );
};

export default Offers;
