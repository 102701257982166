import React, { useState, useEffect } from "react";
import "react-slideshow-image/dist/styles.css";
import styled from "styled-components";
import blankcanvas from "../Assets/Images/yellow.jpeg";
import Typewriters from "../Styles/Typewriters";

const SliderPage = styled.article`
  margin: 0 auto;
  position: relative;
  text-align: center;
  width: 100%;

  h2 {
    font-family: "Raleway", sans-serif;
    margin-top: 2em;
  }

  .slogan {
    background-color: #000000c2;
    background-blend-mode: darken;
    position: relative;
    width: 100vw;
    background-repeat: no-repeat;

    div {
      font-size: 1.5em;
      margin: 0 auto;
      font-family: "Raleway", sans-serif;
      text-decoration: underline #ffa60098;

      h4 {
        font-size: 1.3em;
      }

      @media screen and (max-width: 768px) {
        font-size: 5vw;
      }
    }

    img {
      width: 100%;
      opacity: 40%;
    }
  }
`;

const About = () => {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    if (window.pageYOffset > 50) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  return (
    <SliderPage>
      <div className="slogan">
        <img src={blankcanvas} alt="trainrail" />
        {isScrolled ? (
          <div>
            <Typewriters />
          </div>
        ) : null}
      </div>
    </SliderPage>
  );
};

export default About;
