import { useState, useCallback } from "react";
import OpenAI from "openai";

const useGenerateImage = (reDesign = false) => {
  const [image, setImage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const openai = new OpenAI({
    apiKey: process.env.REACT_APP_OPENAI_API_KEY,
    dangerouslyAllowBrowser: true,
  });

  const generateImage = useCallback(
    async (description, size, userPreferences) => {
      // Opdateret for at acceptere size og userPreferences
      setIsLoading(true);
      setError(null);
      try {
        // Basis prompt, inkluderer nu størrelse og brugerpræferencer
        let promptForImage = `Skab en detaljeret illustration baseret på den beskrevne scene eller objekt '${description}'`;

        if (reDesign) {
          // Opdateret redesign prompt, inkluderer nu både størrelse og brugerpræferencer
          promptForImage = `Med udgangspunkt i den eksisterende tatovering, som er beskrevet således: '${description}', og med en størrelse på '${size}', skal der skabes en ny skitse på hvidt papir. 
          Brugerens specifikke ønsker inkluderer: ${userPreferences}. 
          Det nye design bør skjule det oprindelige mønster eller tekst og integrere det på en måde, så det nye mønster ser harmonisk og tilsigtet ud. 
          Det nye design skal både respektere og forvandle det oprindelige design. Det er meget vigtigt, du skaber en illustration, som står for sig selv på hvid baggrund, som var det en png-fil.`;
        }

        const generate_image_response = await openai.images.generate({
          model: "dall-e-3",
          prompt: promptForImage,
        });

        const newImageUrl = generate_image_response.data[0].url;
        setImage(newImageUrl);
      } catch (error) {
        console.error("Error generating image:", error);
        setError(error);
      } finally {
        setIsLoading(false);
      }
    },
    []
  );

  return { image, isLoading, error, generateImage };
};

export default useGenerateImage;
