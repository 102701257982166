import { useState, useCallback } from "react";
import OpenAI from "openai";

// Tilføjer `reDesign` som en parameter til custom hook
function useGptVision(imageURL, reDesign, userPreferences) {
  // Tilføjet reDesign som en parameter
  const [description, setDescription] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchDescription = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    const openai = new OpenAI({
      apiKey: process.env.REACT_APP_OPENAI_API_KEY,
      dangerouslyAllowBrowser: true,
    });

    // Definerer en grundlæggende prompt
    let promptText =
      "Beskriv detaljeret hvad der er, på dette billede. Farver, linjer, udtryk mm., således at gengivelsen bliver så præcist som muligt.";

    // Tilpas prompt baseret på reDesign
    if (reDesign) {
      promptText = `Beskriv detaljeret hvad der er, på dette billede. Farver, linjer, udtryk mm., således at gengivelsen bliver så præcist som muligt.
      `;
    }

    try {
      const response = await openai.chat.completions.create({
        model: "gpt-4-vision-preview",
        messages: [
          {
            role: "user",
            content: [
              {
                type: "text",
                text: promptText,
              },
              {
                type: "image_url",
                image_url: {
                  url: imageURL,
                  detail: "high",
                },
              },
            ],
          },
        ],
        max_tokens: 1000,
      });

      setDescription(response.choices[0].message.content);
    } catch (e) {
      setError(e);
    } finally {
      setIsLoading(false);
    }
  }, [imageURL, reDesign]);

  return { description, isLoading, error, fetchDescription };
}

export default useGptVision;
