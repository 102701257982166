import { useState, useCallback } from "react";
import appService from "../../Components/Appservices/App.service";
import emailjs from "@emailjs/browser";

export const useTrackClick = () => {
  const [clicked, setClicked] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const publicKey = process.env.REACT_APP_EMAIL_PUBLIC_KEY;

  const postClick = useCallback(
    async (identifier) => {
      setIsLoading(true);
      try {
        const res = await appService.clickTracker({ type: identifier });
        if (res.data.message === "Klik modtaget, men ikke registreret") {
          // Håndter det tilfælde, hvor klikket ikke registreres i databasen
          console.log(res.data.message);
        } else {
          const ip = res.data.ip;
          setClicked((prevCount) => prevCount + 1);
          const templateParams = {
            text: `Der blev klikket på type: ${identifier} fra ip-adressen: ${ip}`,
            from_name: "Din webudvikler",
            to: "annedeveloper@hotmail.com",
            subject: "Ny Klik Aktivitet",
          };

          emailjs.send("service_4c656gf", "template_7czjnje", templateParams, publicKey).then(
            function (response) {
              console.log("SUCCESS!", response.status, response.text);
            },
            function (error) {
              console.log("FAILED...", error);
            }
          );
        }
      } catch (error) {
        console.error("Fejl ved sporing af klik:", error);
      } finally {
        setIsLoading(false);
      }
    },
    [publicKey]
  );

  return { postClick, clicked, isLoading };
};
