import React, { useState, useRef, useCallback, useEffect } from "react";
import styled from "styled-components";
import Loading from "../../Components/Partials/Loading";
import appService from "../../Components/Appservices/App.service";
import { useFlashMessageStore } from "../../Components/FlashMessages/useFlashMessageStore";
import NewTattoo from "./NewTattoo";

const TattooSection = styled.section`
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 2em;
  background-color: #4b424240;
  color: ${(props) => props.theme.colors.text};
  margin: 1em auto;

  header {
    margin-bottom: 2em;
  }

  img {
      width: 25%; 
      height: auto;
      border-radius: 8px; 
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }

  input {
    margin: 2em;
  }
  
`;

const RedesignTattoo = () => {
    const [file, setFile] = useState(null);
    const [imageURL, setImageURL] = useState(""); 
    const [isLoading, setIsLoading] = useState(false);
    const inputReference = useRef(null);
    const { setSuccessMessage, setErrorMessage } = useFlashMessageStore();

    const handleImage = useCallback((e) => {
      const file = e.target.files[0];
      if (file) {
        setIsLoading(true);
        setFile(file);
      }
    }, []);
  
    const postImage = useCallback(async () => {
      if (!file) return; 
      setIsLoading(true);
      const formData = new FormData();
      formData.append("image", file);
  
      try {
        const res = await appService.post_image(formData);
        if (res.data.status === 200) {
          setImageURL(res.data.data.url);
          setSuccessMessage("Billedet er tilføjet!");
          setFile(null);
        }
      } catch (error) {
        console.error("Fejl:", error);
        setErrorMessage("Fejl:", error);
      } finally {
        setIsLoading(false);
      }
    }, [file, setSuccessMessage, setErrorMessage]);
  
    useEffect(() => {
      postImage();
    }, [file, postImage]);
  
    return (
      <TattooSection>
           {isLoading && <Loading />}
        <header>
            <h2>Tilføj et billede</h2>
        </header>
     
        {imageURL && 
     
           <img src={imageURL} alt="Uploaded" />  
      
        }
        <input type="file" onChange={handleImage} ref={inputReference} />
       
       <NewTattoo imageURL={imageURL} reDesign={false}/>
      </TattooSection>
    );
  };
  
  export default RedesignTattoo;