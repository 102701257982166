import React, { useState } from 'react';
import styled from 'styled-components';

const Figure = styled.figure`
  cursor: pointer;

  img {
    width: 50%;
    height: auto;
    object-fit: cover;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }

  &.fullscreen {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    height: 80%;
    z-index: 1000;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
  }
`;

const NewTattooImage = ({ src }) => {
  const [isFullscreen, setIsFullscreen] = useState(false);

  const handleImageClick = () => {
    setIsFullscreen(!isFullscreen);
  };

  return (
    <>
      {isFullscreen && <div className="overlay" onClick={handleImageClick}></div>}
      <Figure className={isFullscreen ? 'fullscreen' : ''} onClick={handleImageClick}>
        <img src={src} alt="Generated" />
      </Figure>
    </>
  );
};

export default NewTattooImage;

