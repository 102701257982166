import styled from "styled-components";

const StyledForm = styled.form`
  padding: 2em;
  width: 40%;
  margin: 0 auto;

  @media screen and (max-width: 600px) {
    padding: 0;
    width: 90%;
  }

  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;

    label {
      text-align: center;
    }

    input,
    select {
      width: 70%;
      padding: 7px;
      font-size: 1em;
      border: white solid 2px;
      margin: 0.5em auto;
      color: grey;
    }

    textarea {
      width: 70%;
      font-size: 1em;
      height: 10vh;
      padding: 7px;
      margin: 0.5em auto;
      color: black;
    }

    button {
      font-weight: 300;
      margin: 1em auto;
    }

    .custom-label {
      margin: 0.5em auto;
    }
  }
`;
export { StyledForm };
