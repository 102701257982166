import React from "react";
import { TypePage } from "./Types.styled";

const Simply = () => {
  return (
    <TypePage>
      <h4>Simpel</h4>
    </TypePage>
  );
};

export default Simply;
