import React, { useState } from "react";
import { useTypewriter } from "react-simple-typewriter";
import styled from "styled-components";
import Quote from "../Components/Partials/Handling_resultater";

const Effect = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  width: 100%;
  top: 50%;
  align-items: center;
`;

const Typewriters = () => {
  const [animationStatus, setAnimationStatus] = useState("start");

  const [text] = useTypewriter({
    words: ["Samtale skaber idéer", "Idéer skaber planlægning", "Planlægning skaber handling"],
    loop: 1,
    onLoopDone: () => {
      setTimeout(() => {
        setAnimationStatus("færdig");
      }, 2500);
    },
  });

  return (
    <>
      <Effect>
        <span style={animationStatus === "færdig" ? { display: "none" } : null}>{text}</span>
      </Effect>
      {animationStatus === "færdig" ? <Quote /> : null}
    </>
  );
};

export default Typewriters;
