import { Routes, Route } from "react-router-dom";
import Notfound from "../../Pages/Notfound";
import Home from "../../Pages/Home/Home";
import Contact from "../../Pages/Contact/Contact";
import { AnimatePresence } from "framer-motion";
import Projects from "../../Pages/Projects/Projects";
import Offers from "../Offers";
import ProductDescriptionGenerator from "../../Pages/ChatGPT/ProductDescriptions";
import TattooGenerator from "../../Pages/ChatGPT/TattooGenerator";
import RedesignTattoo from "../../Pages/ChatGPT/RedesignTattoo";
import DescriptionGenerator from "../../Pages/ChatGPT/OneDayViborg/DescriptionGenerator";
import Header from "../Partials/Header/Header";
import Footer from "../Partials/Footer";

const Router = () => {
  const MinimalLayout = ({ children }) => <>{children}</>;

  const MainLayout = ({ children }) => (
    <>
      <Header />
      {children}
      <Footer />
    </>
  );

  return (
    <>
      <AnimatePresence mode='wait'>
        <Routes>
          <Route
            index
            element={
              <MainLayout>
                <Home />
              </MainLayout>
            }
          />
          <Route
            path='/projects'
            element={
              <MainLayout>
                <Projects />
              </MainLayout>
            }
          />
          <Route path='/contact' element={<Contact />} />
          <Route
            path='/descriptions'
            element={
              <MainLayout>
                <ProductDescriptionGenerator />
              </MainLayout>
            }
          />
          <Route
            path='/tattoo'
            element={
              <MainLayout>
                <TattooGenerator />
              </MainLayout>
            }
          />
          <Route
            path='/oneday'
            element={
              <MinimalLayout>
                <DescriptionGenerator />
              </MinimalLayout>
            }
          />
          <Route
            path='/redesign'
            element={
              <MainLayout>
                <RedesignTattoo />
              </MainLayout>
            }
          />
          <Route
            path='/offers/*'
            element={
              <MainLayout>
                <Offers />
              </MainLayout>
            }
          />
          <Route
            path='*'
            element={
              <MainLayout>
                <Notfound />
              </MainLayout>
            }
          />
        </Routes>
      </AnimatePresence>
    </>
  );
};

export default Router;
