import Router from "./Components/Router/Router";
import { ThemeProvider } from "styled-components";
import FlashMessages from "./Components/FlashMessages/FlashMessages";
import Modal from "./Components/Modal/Modal";
import { Theme } from "./Styles/Themes/Theme";
import { Globals } from "./Styles/Globals.Styled";

function App() {
  return (
    <ThemeProvider theme={Theme}>
      <Globals />
      <FlashMessages />
      <Modal />
      <Router />
    </ThemeProvider>
  );
}

export default App;
