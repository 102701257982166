import { useState, useCallback } from "react";
import OpenAI from "openai";

const useGenerateDescription = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [generatedDescription, setGeneratedDescription] = useState("");
  const [error, setError] = useState(null);

  const openai = new OpenAI({
    apiKey: process.env.REACT_APP_OPENAI_API_KEY,
    dangerouslyAllowBrowser: true,
  });

  const generateDescription = useCallback(
    async ({ type, name, seedWords, job, temperature }) => {
      setIsLoading(true);
      setError(null);

      let promptContent = `<p>Du skal generere en beskrivelse. Teksten skal inkludere: ${seedWords}</p>`;

      switch (type) {
        case "menneske":
          promptContent = `<p>Du skal generere en kort og præcis profil for et ${type}. Navnet på individet er ${name}. Kendetegn inkluderer: ${seedWords} og evt. beskæftigelse: ${job}.</p>
        <h3>Instruktioner:</h3>
        <ul>
              <li>Brug de modtagne stikord til at danne en præcis beskrivelse af personen.</li>
              <li>Inkluder både fysiske og personlige kendetegn, samt personens beskæftigelse hvis denne er angivet.</li>
              <li>Tag hensyn til personens interesser og hvordan de kan afspejles.</li>
              <li>Stræb efter at skabe et sammenhængende og realistisk portræt af personen.</li>
              <li>Brug passende HTML-tags til at strukturere dit output.</li>
              <li>Inkludér overskrifter for at organisere forskellige sektioner.</li>
              <li>Brug aldrig <h1></h1> tags.</li>
              <li>Omslut hver overskrift med den tilhørende tekst i et <section>-tag.</li>
              <li>Brug afsnit til at give detaljerede oplysninger.</li>
              <li>Indarbejd lister med punkttegn, hvor det giver mening.</li>
        </ul>`;
          // console.log(promptContent);
          break;
        case "dyr":
          promptContent = `<p>Du skal generere en kort og præcis profil for et dyr. Navnet på dyret er ${name}. Kendetegn inkluderer: ${seedWords}.</p>
          <h3>Instruktioner:</h3>
          <ul>
                <li>Brug de modtagne stikord til at danne en præcis beskrivelse af dyret.</li>
                <li>Inkluder både fysiske og personlige kendetegn.</li>
                <li>Stræb efter at skabe et sammenhængende og realistisk portræt af dyret.</li>
                <li>Brug passende HTML-tags til at strukturere dit output.</li>
                <li>Inkludér overskrifter for at organisere forskellige sektioner.</li>
                <li>Brug aldrig <h1></h1> tags.</li>
                <li>Omslut hver overskrift med den tilhørende tekst i et <section>-tag.</li>
                <li>Brug afsnit til at give detaljerede oplysninger.</li>
                <li>Indarbejd lister med punkttegn, hvor det giver mening.</li>
          </ul>`;
          // console.log(promptContent);
          break;
        case "ting":
          promptContent = `<p>Du skal generere en kort og præcis beskrivelse af denne ting: ${name}. Teksten skal inkludere: ${seedWords}</p>
          <h3>Instruktioner:</h3>
          <ul>
                <li>Brug de modtagne stikord til at danne en præcis beskrivelse af genstanden.</li>
                <li>Stræb efter at skabe en sammenhængende og realistisk beskrivelse.</li>
                <li>Brug passende HTML-tags til at strukturere dit output.</li>
                <li>Inkludér overskrifter for at organisere forskellige sektioner.</li>
                <li>Brug aldrig <h1></h1> tags.</li>
                <li>Omslut hver overskrift med den tilhørende tekst i et <section>-tag.</li>
                <li>Brug afsnit til at give detaljerede oplysninger.</li>
                <li>Indarbejd lister med punkttegn, hvor det giver mening.</li>
          </ul>`;
          // console.log(promptContent);
          break;
        default:
          promptContent = `<p>Du skal generere en kort og præcis beskrivelse. Teksten skal inkludere: ${seedWords}</p>
          <h3>Instruktioner:</h3>
          <ul>
                <li>Stræb efter at skabe en sammenhængende og realistisk beskrivelse.</li>
                <li>Brug passende HTML-tags til at strukturere dit output.</li>
                <li>Inkludér overskrifter for at organisere forskellige sektioner.</li>
                <li>Brug aldrig <h1></h1> tags.</li>
                <li>Omslut hver overskrift med den tilhørende tekst i et <section>-tag.</li>
                <li>Brug afsnit til at give detaljerede oplysninger.</li>
                <li>Indarbejd lister med punkttegn, hvor det giver mening.</li>
          </ul>`;
          break;
      }

      try {
        const completion = await openai.chat.completions.create({
          temperature: temperature,
          messages: [
            { role: "system", content: promptContent },
            {
              role: "user",
              content:
                `Navn: ${name}\nKendetegn: ${seedWords}` +
                (job ? `\nBeskæftigelse: ${job}` : ""),
            },
          ],
          model: "gpt-4",
        });

        setGeneratedDescription(completion.choices[0].message.content);
      } catch (error) {
        console.error("Error generating description:", error);
        setError(error);
      } finally {
        setIsLoading(false);
      }
    },
    []
  );

  return {
    isLoading,
    generatedDescription,
    error,
    generateDescription,
  };
};

export default useGenerateDescription;
