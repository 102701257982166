const API_MEMBER1 = "https://wishlists-api-annelund.vercel.app/member1";

const API_MEMBER2 = "https://wishlists-api-annelund.vercel.app/member2";

const API_MEMBER3 = "https://wishlists-api-annelund.vercel.app/member3";

const API_MEMBER4 = "https://wishlists-api-annelund.vercel.app/member4";

const API_ALLMEMBERS = "https://wishlists-api-annelund.vercel.app/allmembers";

const IMG_POST =
  "https://api.imgbb.com/1/upload?&key=e8a00b56282f272fb0399ee781aa3a44";

const api_clickTracker = "https://wishlists-api-annelund.vercel.app/click";

const api_emailPost = "https://wishlists-api-annelund.vercel.app/send-email";

export {
  API_MEMBER1,
  API_MEMBER2,
  API_MEMBER3,
  API_MEMBER4,
  API_ALLMEMBERS,
  IMG_POST,
  api_clickTracker,
  api_emailPost,
};
