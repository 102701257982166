import React from "react";
import styled from "styled-components";
import { FaRegCopyright } from "react-icons/fa";
import { SiGnuprivacyguard } from "react-icons/si";
import { FaCookieBite } from "react-icons/fa";
import { Link } from "react-router-dom";

const MainFooter = styled.footer`
  display: flex;
  justify-content: space-between;
  padding: 0.5em 5em;
  align-items: center;
  height: 10vh;
  position: relative;
  background-blend-mode: darken;
  background-color: #8282821d;
  background-size: cover;
  background-position: bottom;

  p,
  span {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  p {
    padding: 0 0.5em;
    display: flex;
    gap: 0.2em;
  }

  a {
    color: white;
  }

  .privacy {
    display: flex;
    gap: 0.5em;
  }

  @media screen and (max-width: 768px) {
    display: flex;

    gap: 0.4em;
    padding: 2em;
    margin: 0;
    font-size: 0.7em;

    .copyright {
      display: none;
    }

    .contactLink {
      display: none;
    }
  }
`;

const Footer = () => {
  return (
    <MainFooter>
      <Link to="/contact" className="contactLink">
        Kontaktoplysninger
      </Link>
      <p className="copyright">
        <span>
          <FaRegCopyright />
        </span>
        opyright
      </p>
      <div className="privacy">
        <p>
       <span>
       <SiGnuprivacyguard />
        </span> 
          <a
            href="https://www.iubenda.com/privacy-policy/31359010"
            title="Privatlivspolitik ">
            Privatlivspolitik
          </a>
        </p>
        <p>
          <span>
            <FaCookieBite />
          </span>
          <a
            href="https://www.iubenda.com/privacy-policy/31359010/cookie-policy"
            title="Cookiepolitik"
            >
            Cookiepolitik
          </a>
        </p>
      </div>
    </MainFooter>
  );
};

export default Footer;
