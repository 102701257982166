import React from "react";
import Contactform from "../../Pages/Contact/Contactform";
import Transitions from "../../Styles/Transition";
import Page from "../../Styles/Page.Styled";

const Contact = () => {
  return (
    <Transitions>
      <Page style={{ backgroundColor: "#000000f1" }}>
        <header>
          <h3>Kontakt</h3>
          <h4>Lad os tage en snak og skabe resultater sammen!</h4>
        </header>
        <Contactform />
      </Page>
    </Transitions>
  );
};

export default Contact;
