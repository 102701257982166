import styled from "styled-components";
import React from "react";
import Page from "../../Styles/Page.Styled";
import Transitions from "../../Styles/Transition";
import Contact from "../Contact/Contact";
import About from "../About";
import { BsFillSuitHeartFill } from "react-icons/bs";

const Animation = styled.div`
  position: relative;
  width: 100%;

  h2 {
    color: #ffffff;
    font-size: 1.5em;
    animation: fade-in 5s forwards;
    animation-delay: 1s;
    text-shadow: black 10px 5px 5px;
    opacity: 0%;
    text-align: left;
    margin-top: -0.5em;
    display: flex;
    justify-content: flex-start;

    .heart {
      animation: spin 1s ease-in-out forwards;
      animation-delay: 2s;
      margin: 0.1em 0.3em;
      opacity: 0;

      @keyframes spin {
        from {
          transform: rotate(0deg);
          opacity: 10%;
        }
        to {
          transform: rotate(360deg);
          opacity: 100%;
        }
      }
    }
  }

  @keyframes fade-in {
    0% {
      opacity: inherit;
    }

    100% {
      opacity: 100%;
    }
  }

  svg {
    width: 100%;
    stroke-width: 0.5px;
  }

  @media screen and (max-width: 768px) {
    h2 {
      display: flex;
      flex-direction: column;
      font-size: 4vw;
      text-align: center;
      margin: 0.3em 0;
      line-height: 0.8em;
      .heart {
        margin: 0.3em;
      }
    }
  }
`;
const Home = () => {
  return (
    <>
      <Transitions>
        <Page>
          <header>
            <h1>
              <span>Din</span> webudvikler
            </h1>
            <Animation>
              <h2>
                Teknologi med hjertet
                <span className="heart">
                  <BsFillSuitHeartFill fill="#ffa60098" size={20} />
                </span>
              </h2>
            </Animation>
          </header>
        </Page>
        <About />
        <Contact />
      </Transitions>
    </>
  );
};

export default Home;
