import { createGlobalStyle } from "styled-components";

export const Globals = createGlobalStyle`
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-display: swap;
}

body, html {
    width: 100%;
    height: 100%;
    background-color: black;
    }

html {
    background-color: ${({ theme }) => theme.colors.background};
    color: ${({ theme }) => theme.colors.onBackground}
}

#root {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr auto;
    min-height: 100%;
    overflow: hidden;
    color: white;
    font-family: 'Gantari', sans-serif;
}

textarea, button, input {
  font-family: 'Gantari', sans-serif;
}

button{
    background-color: #ffffff;
    margin: 1em auto;
    padding: .5em;
    border: white solid 1px;
    font-weight: 300;
    color: black;
    font-size: 1em;

    &:hover{
      background-color: #ffa60098;
      color: white;
      cursor: pointer;
      transition: 300ms;
    }
  } 


  
`;
