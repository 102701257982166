import React, { useEffect, useState } from 'react';
import useGptVision from '../../Hooks/useGptVision';
import useGenerateImage from '../../Hooks/useGenerateImage';
import Transitions from "../../Styles/Transition";
import NewTattooImage from './NewTattooImage';
import styled from 'styled-components';

const Container = styled.section`
  width: 100%;
`;

const InputContainer = styled.div`
  margin: 20px 0;
`;

const NewTattoo = ({ imageURL, reDesign }) => {
  const { description, isLoading, error, fetchDescription } = useGptVision(imageURL, reDesign);
  const { image: generatedImage, isLoading: isLoadingImage, error: errorImage, generateImage } = useGenerateImage(reDesign);
  const [tattooSize, setTattooSize] = useState('medium');
  const [userPreferences, setUserPreferences] = useState(''); // Ny state for brugerens specifikke ønsker

  useEffect(() => {
    if (description && !generatedImage) {
      generateImage(description, tattooSize, userPreferences); // Opdateret med size og userPreferences
    }
  }, [description, generateImage, generatedImage, tattooSize, userPreferences]);

  const handleDescribeClick = () => {
    if (imageURL) {
      fetchDescription();
    }
  };

  const handleSizeChange = (event) => {
    setTattooSize(event.target.value);
  };

  const handlePreferencesChange = (event) => {
    setUserPreferences(event.target.value); // Opdaterer state baseret på brugerens input
  };

  if (isLoading || isLoadingImage) return <div>Loading...</div>;
  if (error || errorImage) return <div>Error: {error?.message || errorImage?.message}</div>;

  return (
    <Transitions>
      <Container>
        {imageURL && (
          <>
          {reDesign && 
          <>
          <InputContainer>
              <label>Vælg tatoveringens størrelse: </label>
              <select value={tattooSize} onChange={handleSizeChange}>
                <option value="small">Lille</option>
                <option value="medium">Medium</option>
                <option value="large">Stor</option>
              </select>
            </InputContainer>
            <InputContainer>
              <label>Skriv dine specifikke ønsker her: </label>
              <textarea value={userPreferences} onChange={handlePreferencesChange} placeholder="Farver, stilarter, symboler osv." />
            </InputContainer></>}
            
            <button onClick={handleDescribeClick}>
              {generatedImage ? 'Prøv igen' : 'Generér nyt billede'}
            </button>
          </>
        )}
        
        {description && <>
          {generatedImage && <NewTattooImage src={generatedImage} />}
        </>}
        
      </Container>
    </Transitions>
  );
};

export default NewTattoo;




