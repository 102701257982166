import { useState, useCallback } from "react";
import OpenAI from "openai";

const useGenerateDescription = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [generatedDescription, setGeneratedDescription] = useState("");
  const [error, setError] = useState(null);

  const openai = new OpenAI({
    apiKey: process.env.REACT_APP_OPENAI_API_KEY,
    dangerouslyAllowBrowser: true,
  });

  const generateDescription = useCallback(
    async ({ title, seedWords, atmosphere, temperature }) => {
      setIsLoading(true);
      setError(null);

      let promptContent = `
        Du skal generere en beskrivelse ud fra nogle givne nøgleord, en bestemt tone of voice og en titel.
        De givne nøgleord omhandler et øjeblik i en given situation.

        Instruktioner:
        
        Brug de modtagne stikord '${seedWords}' til at danne en kort, præcis beskrivelse af situationen.
        Titlen på beskrivelsen er '${title}'.
        Tone of Voice skal være: ${atmosphere}.

        Brug <code>&lt;section&gt;</code> tags til at organisere tekst i sektioner.
        Indarbejd <code>&lt;h2&gt;</code> eller <code>&lt;h3&gt;</code> for overskrifter (aldrig <code>&lt;h1&gt;</code>).
        Brug <code>&lt;p&gt;</code> for afsnit og <code>&lt;ul&gt;</code> eller <code>&lt;ol&gt;</code> for lister.
    `;

      try {
        const completion = await openai.chat.completions.create({
          temperature: temperature,
          messages: [
            { role: "system", content: promptContent },
            {
              role: "user",
              content: `Titel: ${title}\nBeskrivelse: ${seedWords}\nStemning: ${atmosphere}`,
            },
          ],
          model: "gpt-4",
        });

        setGeneratedDescription(completion.choices[0].message.content);
      } catch (error) {
        console.error("Error generating description:", error);
        setError(error);
      } finally {
        setIsLoading(false);
      }
    },
    []
  );

  return {
    isLoading,
    generatedDescription,
    error,
    generateDescription,
  };
};

const generateTitleIfNeeded = async (seedWords, temperature) => {
  if (!seedWords) return ""; // Returnerer en tom streng, hvis der ikke er seedWords

  const openai = new OpenAI({
    apiKey: process.env.REACT_APP_OPENAI_API_KEY,
    dangerouslyAllowBrowser: true,
  });

  const prompt = `Udform en kort titel (MAX 7 ord) baseret på følgende nøgleord: ${seedWords}.
  Titlen skal stå i et passende HTML-tag.
  `;

  try {
    const completion = await openai.chat.completions.create({
      temperature: temperature,
      messages: [{ role: "system", content: prompt }],
      model: "gpt-4",
    });
    return completion.choices[0].message.content;
  } catch (error) {
    console.error("Error generating title:", error);
    return "Untitled";
  }
};

export { useGenerateDescription, generateTitleIfNeeded };
