import React, { useState, useEffect, useCallback } from "react";
import OpenAI from "openai";
import { useNavigate } from "react-router-dom";
import { StyledArticle, Result } from "./Description.Styled";
import Loading from "../../Components/Partials/Loading";
import Transitions from "../../Styles/Transition";
import { StyledForm } from "../../Styles/Form.Styled";
import { IoMdArrowRoundBack } from "react-icons/io";

const TattooGenerator = () => {
  const [state, setState] = useState({
    seedWords: "",
    generatedDescription: "",
    image: "",
    temperature: 0.3,
    isLoading: { image: false, description: false },
    result: false,
    colours: "",
    userSatisfied: null,
    editMode: false,
    editableDescription: "",
  });

  const initialState = {
    seedWords: "",
    generatedDescription: "",
    image: "",
    temperature: 0.3,
    isLoading: { image: false, description: false },
    result: false,
    colours: "",
    userSatisfied: null,
    editMode: false,
    editableDescription: "",
  };
  

  const { generatedDescription, editMode, temperature, colours, editableDescription, image } = state;
 
  const preloadImage = (src) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = src;
      img.onload = resolve;
      img.onerror = reject;
    });
  };

  const openai = new OpenAI({
    apiKey: process.env.REACT_APP_OPENAI_API_KEY,
    dangerouslyAllowBrowser: true,
  });

  const generateTextDescription = async (e) => {
    e.preventDefault();
    // Opdaterer isLoading.description til true for at indikere, at beskrivelsen er ved at blive genereret
    setState(prevState => ({
      ...prevState,
      isLoading: {
        ...prevState.isLoading,
        description: true,
      },
    }));
  
    try {
      const { seedWords, colours, temperature } = state;
      let promptContent = `<p>Du er nu en online skribent. Du får en kundes beskrivelse af en ønsket tatovering, 
      som du skal præcisere og beskrive til en tatovør, så denne ikke er i tvivl om, hvordan skitsen til tatoveringen skal se ud. 
       </p>
        <h3>Instruktioner:</h3>
        <ul>
              <li>Brug de modtagne stikord til at danne en præcis beskrivelse af tatoveringen</li>
              <li>Kundens beskrivelse er som følger: ${seedWords}.</li>
              <li>Hvis angivet, skal du rette dig efter kundens ønsker om farver: ${colours}</li>
              <li>Brug passende HTML-tags til at strukturere dit output.</li>
              <li>Inkludér overskrifter for at organisere forskellige sektioner.</li>
              <li>Brug aldrig <h1></h1> tags.</li>
              <li>Omslut hver overskrift med den tilhørende tekst i et <section>-tag.</li>
              <li>Brug afsnit til at give detaljerede oplysninger.</li>
              <li>Indarbejd lister med punkttegn, hvor det giver mening.</li>
        </ul>`;
  
      const completion = await openai.chat.completions.create({
        temperature: temperature,
        messages: [
          { role: "system", content: promptContent },
          { 
            role: "user", 
            content: `Kendetegn: ${seedWords}` + (colours ? `\nFarver: ${colours}` : ""),
          },
        ],
        
        top_p: 1,
        frequency_penalty: 0,
        presence_penalty: 0,
        model: "gpt-4",
      });
  
      // Opdaterer både isLoading.description og generatedDescription i en enkelt setState kald
      setState(prevState => ({
        ...prevState,
        generatedDescription: completion.choices[0].message.content,
        isLoading: {
          ...prevState.isLoading,
          description: false,
        },
      }));
    } catch (error) {
      console.error("Error generating description:", error);
      // Sæt isLoading.description til false i tilfælde af fejl
      setState(prevState => ({
        ...prevState,
        isLoading: {
          ...prevState.isLoading,
          description: false,
        },
      }));
    }
  };
  

  const generateImage = useCallback(async () => {
    // Sætter isLoading.image til true
    setState(prevState => ({
      ...prevState,
      isLoading: {
        ...prevState.isLoading,
        image: true,
      },
    }));
  
    try {
      const { editableDescription, generatedDescription, colours } = state;
      // Bestemmer hvilken beskrivelse der skal bruges
      const descriptionToUse = editableDescription && editableDescription !== generatedDescription ? editableDescription : generatedDescription;
  
      let promptForImage = `
      Du er nu en multimediegrafiker, der skaber en unik tatoveringsskitse via et illustrationsprogram. Baseret på følgende beskrivelse:
      '${descriptionToUse}', skal du udforme en illustration, der præcist fremviser designet og de ønskede farver (${colours}) 
      Det er meget vigtigt at perspektivet er NORMAL-PERSPEKTIV og at der INTET er på illustrationen, udover det ønskede. Designet skal være enkelt og med få linjer og nuancer. 
      Din opgave er at fokusere udelukkende på tatoveringens design, 
      som det vil se ud på huden, og sikre, at designet er klar til tatovering.`;
  
      const generate_image = await openai.images.generate({
        model: "dall-e-3",
        prompt: promptForImage,
      });
  
      const newImageUrl = generate_image.data[0].url;
      await preloadImage(newImageUrl);
  
      // Opdaterer både isLoading.image og image URL i en enkelt setState kald
      setState(prevState => ({
        ...prevState,
        image: newImageUrl,
        result: true, // Antager dette markerer, at resultatet er klar til visning
        isLoading: {
          ...prevState.isLoading,
          image: false,
        },
      }));
    } catch (error) {
      console.error("Error generating image:", error);
      // Sæt isLoading.image til false i tilfælde af fejl
      setState(prevState => ({
        ...prevState,
        isLoading: {
          ...prevState.isLoading,
          image: false,
        },
      }));
    }
  }, [editableDescription, generatedDescription, colours]);
  

  useEffect(() => {
    const { generatedDescription, image } = state;
    if (generatedDescription && !image) {
      generateImage();
    }
  }, [state.generatedDescription, state.image, generateImage]);
  
  const handleEditDescription = () => {
    setState(prevState => ({
      ...prevState,
      editMode: true, // Aktiverer redigeringsmodus
      editableDescription: prevState.generatedDescription, // Indlæser den genererede beskrivelse til redigering
    }));
  };
  
  const handleSaveAndRegenerate = async () => {
    // Bruger en midlertidig variabel for at undgå direkte afhængighed af state i en asynkron funktion
    const newEditableDescription = state.editableDescription;
  
    setState(prevState => ({
      ...prevState,
      generatedDescription: newEditableDescription,
      editMode: false, // Afslutter redigeringsmodus
      userSatisfied: null, // Nulstiller brugerens tilfredshedstilstand
      // Bemærk: Vi opdaterer ikke image her, da det vil blive håndteret af generateImage funktionen
    }));
  
    // Gen-generer billedet baseret på den opdaterede beskrivelse
    await generateImage();
  };

  const resetApplicationState = () => {
    setState({
      ...initialState, // Antag, at du har defineret en initialState variabel med dine startværdier
    });
  };
  
  return (
    <Transitions>
      {!generatedDescription || editMode ? (
        <StyledArticle>
          
          <header className="form-introduction">
           <h1>Tatoverings-generator</h1> 
            <p>Udfyld formularen nedenfor for at generere en tatovering ud fra dine kriterier.</p>
            <br/>  <p>'Temperatur' justerer robottens kreativitet. Minimun er 0.1 og maximum er 1.0</p>
          </header>

          {editMode ? (
            <>
           <textarea
            value={state.editableDescription}
            onChange={(e) => {
              const newValue = e.target.value;
              setState(prevState => ({
                ...prevState,
                editableDescription: newValue,
              }));
            }}
            rows="10"
            style={{ width: '100%' }}
          />

              <button onClick={handleSaveAndRegenerate}>Gem og generér igen</button>
            </>
          ) :

          <StyledForm onSubmit={e => e.preventDefault()}>
            <div className="inputs">

            {/* Stikord/kendetegn*/}
            <textarea
              id="seedWords"
              className="custom-label"
              value={state.seedWords}
              onChange={(e) => {
                const newValue = e.target.value;
                setState(prevState => ({
                  ...prevState,
                  seedWords: newValue,
                }));
              }}
              rows="4"
              required
              placeholder="Beskrivelse"
            >
            </textarea>


            {/* Farver */}
              <textarea
                id="color"
                className="custom-label"
                value={colours}
                onChange={(e) => {
                  const newValue = e.target.value;
                  setState(prevState => ({
                    ...prevState,
                    colours: newValue,
                  }));
                }}
                rows="4"
                placeholder="Farver"
              >
              </textarea>


            {/* Temperatur */}
            <label htmlFor="temperature" className="custom-label">
              Temperatur:
            </label>
            <input
              type="number"
              id="temperature"
              value={temperature}
              onChange={(e) => {
                const newValue = parseFloat(e.target.value);
                setState(prevState => ({
                  ...prevState,
                  temperature: newValue,
                }));
              }}
              step="0.1"
              min="0.1"
              max="1.0"
            />

            <div className="buttons">
              <button type="button" onClick={generateTextDescription}>
                Generér tatovering
              </button>

              {/* <p>eller</p>

              <button type="button" onClick={() => navigate("/redesign")}>Regenerér allerede eksisterende tatovering</button> */}
            </div>
              
            </div>
          </StyledForm>}
        </StyledArticle>
      ) : (
        <>
        {state.result && <Result animate={!!generatedDescription}>
        <div className="content-container">
          <figure>
            <picture>
              {image && <img src={image} alt="Generated" />}
            </picture>
           
          </figure>
          <div className="text-container">
            <div dangerouslySetInnerHTML={{ __html: generatedDescription }} />
            <button className="mobile-back" onClick={() => resetApplicationState()}><IoMdArrowRoundBack color="white" size={30} /></button>
          </div>
        </div>
        <footer>
         <button className="mobile-back" onClick={() => resetApplicationState()}>Tilbage</button>
        <button onClick={handleEditDescription}>Rediger beskrivelse</button>  
        </footer>
        </Result>}
        
        </>)}
        {state.isLoading.description && <Loading loadingText="Genererer billedbeskrivelse.." />}
        {state.isLoading.image && <Loading loadingText="Genererer billede.." />}
    </Transitions>
  );
};

export default TattooGenerator;