import styled from "styled-components";

const StyledArticle = styled.article`
  width: 100%;
  .form-introduction {
    margin-bottom: 2em;
    text-align: center;
    width: 60%;
    margin: 0 auto;
    h1 {
      text-align: center;
      font-size: 2em;
      margin: 1em;
      text-shadow: white 1px 1px 1px;
    }
    @media (max-width: 768px) {
      width: 90%;
      margin: 2em auto;
      h1 {
        font-size: 1.5em;
      }
    }
  }
  button {
    margin: 0 auto;
    display: flex;
    justify-content: center;
    text-align: center;
    font-weight: 400;
  }
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 25%;
  margin: 5em auto 0em auto;

  @media (max-width: 768px) {
    width: 60%;
    button {
      width: 100%;
    }
  }
`;

const Result = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;

  animation: ${({ animate }) => (animate ? "slideIn 1s forwards" : "none")};

  @keyframes slideIn {
    from {
      transform: translateY(-100%);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }

  .content-container {
    display: flex;
    justify-content: space-between; /* Fordeler plads mellem items */
    align-items: center; /* Sørger for at børnelementer strækker sig til fuld højde */
    width: 80%; /* Fylder den tilgængelige bredde */
    margin: 0 auto;
    background-color: rgba(255, 255, 255, 0.2);
    padding: 1em;
    margin: 5em auto 1em auto;

    @media (max-width: 768px) {
      flex-direction: column; /* Stabler items vertikalt på mindre skærme */
    }
  }

  figure,
  .text-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start; /* Sørger for at indholdet starter fra toppen */
    flex: 1; /* Gør at både figure og text-container fylder lige meget */
    padding: 1em;

    section {
      margin-bottom: 1em;
      h2 {
        margin: 1em 0 0.3em 0;
      }
    }

    ul {
      margin: 1em;
      li {
        margin-bottom: 0.5em;
      }
    }

    .desktop-back {
      display: flex;
      justify-content: center;
      transition: 500ms ease-in-out;
      background-color: rgba(255, 255, 255, 0.5);
      margin: 0.5em auto;
      padding: 0.5em;

      &:hover {
        background-color: black;
      }

      @media (max-width: 768px) {
        display: none;
      }
    }

    .mobile-back {
      display: none;
      justify-content: center;
      transition: 500ms ease-in-out;
      background-color: rgba(255, 255, 255, 0.5);
      margin: 0.5em;
      padding: 0.5em;

      &:hover {
        background-color: black;
      }

      @media (max-width: 768px) {
        display: flex;
        margin: 0 auto;
      }
    }
  }

  figure {
    margin: 20px;
    .imageTitle {
      display: flex;
      align-items: center;
      flex-direction: column;
      color: white;
      font-size: 2em;
      text-align: center;
      background-color: rgba(255, 255, 255, 0.2);
      font-style: italic;
      padding: 0.5em;
      a {
        margin-top: 1em;
        span {
          transition: 500ms ease-in-out;
          display: flex;
          margin: 0.5em;
          padding: 0.3em;
          margin: 0 auto;
          justify-content: center;
          &:hover {
            background-color: black;
          }
        }

        @media (max-width: 768px) {
          display: none;
        }
      }
    }
  }

  @media (max-width: 768px) {
    .content-container {
      flex-direction: column;
    }

    figure,
    .text-container {
      width: 100%;
    }
  }
  picture {
    width: 100%;
    img {
      width: 100%;
      object-fit: contain;
    }
  }

  figcaption,
  .text-container {
    text-align: start;
  }

  @media (max-width: 768px) {
    figure,
    .text-container {
      width: 100%;
    }
  }

  footer {
    display: flex;
    flex-direction: column;

    button {
      color: black;
      font-weight: 400;
    }
  }
`;

export { StyledArticle, StyledForm, Result };
