import React, { useState, useEffect } from "react";
import { StyledArticle, Result } from "./Description.Styled";
import Loading from "../../Components/Partials/Loading";
import Transitions from "../../Styles/Transition";
import { StyledForm } from "../../Styles/Form.Styled";
import { IoMdArrowRoundBack } from "react-icons/io";
import useGenerateDescription from "../../Hooks/useGenerateDescription";
import useGenerateImage from "../../Hooks/useGenerateImage";

const ProductDescriptionGenerator = () => {
  const [formState, setFormState] = useState({
    type: 'menneske',
    name: '',
    seedWords: '',
    job: '',
    temperature: 0.5,
  });
 
  const { isLoading: loadingForDescription, generatedDescription, error: descriptionError, generateDescription } = useGenerateDescription();
  const { image, isLoading: imageLoading, error: imageError, generateImage } = useGenerateImage();
  const [result, setResult] = useState(false);
 
  useEffect(() => {
    if (generatedDescription) {
      generateImage(generatedDescription);
    }
  }, [generatedDescription, generateImage]);

  const handleSubmit = (e) => {
    e.preventDefault();
    generateDescription(formState);
  };

  useEffect(() => {
    if (image) {
      setResult(true);
    }
  }, [image]);

  return (
    <Transitions>
      {!generatedDescription || imageLoading || loadingForDescription || !result ? (
        <StyledArticle>
          
          <header className="form-introduction">
           <h1>Karakter-generator</h1> 
            <p>Udfyld formularen nedenfor for at generere et individ eller en ting baseret på navn, kendetegn (Hud, kropsbygning, øjne, næse, ører, frisure osv.) og evt. job.</p>
            <br/>  <p>'Temperatur' justerer kreativiteten af den genererede tekst. Minimun er 0.1 og maximum er 1.0</p>
          </header>

          <StyledForm onSubmit={handleSubmit}>
            <div className="inputs">

            {/* Type */}
            <label htmlFor="type" className="custom-label">Type:</label>
            <select
              id="type"
              value={formState.type}
              onChange={(e) => setFormState(prevState => ({ ...prevState, type: e.target.value }))}
              required
            >
              <option value="menneske">Menneske</option>
              <option value="dyr">Dyr</option>
              <option value="ting">Ting</option>
            </select>

            {/* Titel/navn */}
            <input
              type="text"
              id="name"
              value={formState.name}
              onChange={(e) => setFormState(prevState => ({ ...prevState, name: e.target.value }))}
              required
              placeholder="Navn"
            />

            {/* Stikord/kendetegn */}
            <textarea
              id="seedWords"
              className="custom-label"
              value={formState.seedWords}
              onChange={(e) => setFormState(prevState => ({ ...prevState, seedWords: e.target.value }))}
              rows="4"
              required
              placeholder="Kendetegn"
            ></textarea>

            {/* Job (kun hvis type er 'menneske') */}
            {formState.type === "menneske" && (
              <textarea
                id="job"
                className="custom-label"
                value={formState.job}
                onChange={(e) => setFormState(prevState => ({ ...prevState, job: e.target.value }))}
                rows="4"
                placeholder="Job"
              ></textarea>
            )}

            {/* Temperatur */}
            <label htmlFor="temperature" className="custom-label">Temperatur:</label>
            <input
              type="number"
              id="temperature"
              value={formState.temperature}
              onChange={(e) => setFormState(prevState => ({ ...prevState, temperature: parseFloat(e.target.value) }))}
              step="0.1"
              min="0.1"
              max="1.0"
            />

              <button type="submit">
                Generér karakter
              </button>
            </div>
          </StyledForm>
        </StyledArticle>
      ) : (
        <>
        {result && <Result animate={!!generatedDescription}>
        <div className="content-container">
          <figure>
            <picture>
              {image && <img src={image} alt="Generated" />}
            </picture>
            <figcaption className="imageTitle">
            <button className="desktop-back" onClick={() => setResult(false)}><IoMdArrowRoundBack color="white" size={30} /></button>
            </figcaption>
           
          </figure>
          <div className="text-container">
            <div dangerouslySetInnerHTML={{ __html: generatedDescription }} />
            <button className="mobile-back" onClick={() => setResult(false)}><IoMdArrowRoundBack color="white" size={30} /></button>
          </div>
        </div>
        </Result>}
        
        </>)}
      {loadingForDescription && <Loading loadingText="Genererer tekst.." />}
      {imageLoading && <Loading loadingText="Genererer billede.." />}
    </Transitions>
  );
};

export default ProductDescriptionGenerator;
