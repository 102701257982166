import styled from "styled-components";
import code from "../Assets/Images/code.jpeg";

const Page = styled.section`
  background-image: url(${code});
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: top;
  background-blend-mode: darken;
  background-color: #000000f1;
  background-size: cover;
  min-height: 90vh;
  padding: 2em 4em;
  display: flex;
  width: 100vw;
  flex-direction: column;
  justify-content: center;
  position: relative;
  align-items: center;
  margin: 0;
  text-align: center;

  header {
    text-align: center;
    padding: 1em;
  }

  h1 {
    font-size: 4em;
    margin-bottom: -0.2em;
    text-shadow: black 10px 5px 10px;
    span {
      color: #ffa600aa;
      display: block;
      margin: 0 0 -0.5em 0;
    }
  }

  h3 {
    font-size: 3em;
    margin: 0 0 0.1em 0;
    border-bottom: solid 3px #ffa60098;
    width: 80vw;
    margin: 0 auto;
  }

  h4 {
    margin-top: 1em;
    font-size: 2em;
  }

  ul {
    padding: 2em;

    li {
      margin-bottom: 1em;
      a {
        color: white;
        font-size: 1.6em;

        &:hover {
          color: #ffa60098;
          cursor: pointer;
          transition: 300ms;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    min-height: 25vw;
    padding: 1.5em 0;
    header {
      padding: 1em 0.5;

      h3 {
        font-size: 8vw;
      }

      h4 {
        font-size: 6vw;
      }
    }

    h1 {
      font-size: 10vw;
      margin-bottom: -0.1em;
    }

    li {
      font-size: 0.7em;
    }
  }
`;
export default Page;
