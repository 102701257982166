import styled from "styled-components";

const Quote = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  animation: slide-in 3s ease-in-out forwards;
  opacity: 0;
  position: absolute;
  top: 50%;
  z-index: 2;

  h4 {
    font-size: 5em;

    @keyframes slide-in {
      from {
        opacity: 0%;
      }
      to {
        opacity: 100%;
      }
    }

    @media screen and (max-width: 768px) {
      font-size: 5vw;
    }
  }
`;

const Handling_resultater = () => {
  return (
    <Quote>
      <h4>Handling skaber resultater</h4>
    </Quote>
  );
};

export default Handling_resultater;
