import React from "react";
import Transitions from "../../Styles/Transition";
import Page from "../../Styles/Page.Styled";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useTrackClick } from "./useTrackClick";

const Container = styled.div`
  display: flex;
  padding: 2em;

  li {
    list-style: none;
  }
  a {
    color: white;
    font-size: 1em;

    &:hover {
      color: #d2d2d25f;
      transition: 300ms ease-in-out;
    }
  }

  figure {
    margin: 2em;
    display: flex;
    flex-direction: column;
    background-color: #00000099;
    padding: 1em;

    h4 {
      font-size: 1em;
      margin-top: 0.5em;
    }
    picture {
      width: 100%;

      img {
        width: 200px;
        aspect-ratio: 1/1;
        object-fit: cover;
      }
    }
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    padding: 0;
  }
`;

const Projects = () => {
  const { postClick } = useTrackClick();

  return (
    <Transitions>
      <Page>
        <header>
          <h3>Projekter</h3>
        </header>

        <Container>
          <ul>
            <li onClick={() => postClick("wishlists")}>
              <a href="https://www.wishlists.dinwebudvikler.dk/" target="_blank" rel="noreferrer">
                Ønskeliste
              </a>
            </li>

            <li onClick={() => postClick("character")}>
              <Link to="/descriptions">Karakter-generator</Link>
            </li>

            {/* <li onClick={() => postClick("tattoo")}>
              <Link to="/tattoo">Tatoverings-generator</Link>
            </li> */}
          </ul>
        </Container>
      </Page>
    </Transitions>
  );
};

export default Projects;
