import styled, { keyframes } from 'styled-components';

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const LoaderContainer = styled.div`
  position: fixed; /* Ændret fra 'absolute' til 'fixed' for at dække hele viewport */
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.792); /* Mørk baggrund med gennemsigtighed */
  z-index: 1000; /* Sørg for at det ligger over andre elementer */
`;

const Spinner = styled.div`
  border: 16px solid rgba(0, 0, 0, 0.1); /* Light grey */
  border-top: 16px solid #348ddb; /* Blue */
  border-radius: 50%;
  width: 5em;
  height: 5em;
  animation: ${spin} 2s linear infinite;
`;

const LoadingText = styled.p`
  margin-top: 20px;
  color: #fff; /* Ændret til hvid for bedre synlighed mod den mørke baggrund */
  font-size: 1.2em;
`;

const Loading = ({ loadingText = "Indlæser..." }) => (
  <LoaderContainer>
    <Spinner />
    <LoadingText>{loadingText}</LoadingText>
  </LoaderContainer>
);

export default Loading;


