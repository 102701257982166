import React from "react";
import { Link } from "react-router-dom";
import { MainNav } from "./MainNav.styled";
import { useState, useEffect } from "react";
import styled from "styled-components";
import { useIsOpenNavStore } from "./useIsOpenNavStore";
import logo from "../../../Assets/Images/logo_simple.png";

const MainHeader = styled.header`
  position: relative;
`;
const Hamburger = styled.div`
  flex-direction: column;
  padding: 1em;
  cursor: pointer;
  span {
    height: 5px;
    width: 25px;
    background-color: #ffffff;
    margin-bottom: 4px;
    border-radius: 5px;

    @media screen and (max-width: 768px) {
      display: flex;
    }
  }
`;

const Menu = styled.nav`
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1.5em;
  position: relative;

  @media (max-width: 768px) {
    flex-direction: column;
    transition: 300ms ease-in;
    width: 100%;
    background-color: #000000d4;
    padding-top: ${({ isOpen }) => (isOpen ? "3em" : "0")};
    backdrop-filter: blur(4px);
    justify-content: flex-start;
    z-index: 50000;
    height: ${({ isOpen }) => (isOpen ? "90vh" : "0")};
  }
`;

const Header = () => {
  const { isOpen, setIsOpen } = useIsOpenNavStore();
  const [shrinkHeader, setShrinkHeader] = useState(false);

  useEffect(() => {
    const handler = () => {
      let shrink = document.body.scrollTop > 50 || document.documentElement.scrollTop > 50 ? true : false;
      setShrinkHeader(shrink);
    };

    window.addEventListener("scroll", handler);
    return () => window.removeEventListener("scroll", handler);
  }, []);

  return (
    <MainHeader>
      <MainNav shrinkHeader={shrinkHeader}>
        <Hamburger onClick={() => setIsOpen(!isOpen)}>
          <span></span>
          <span></span>
          <span></span>
        </Hamburger>
        <li>
          <Link to="/">
            <img src={logo} alt="logo" />
          </Link>
        </li>
        <Menu roll isOpen={isOpen} onClick={() => setIsOpen(!isOpen)}>
          <li>
            <Link to="/">Forside</Link>
          </li>
          <li>
            <Link to="/contact">Kontakt</Link>
          </li>
          {/* <li>
            <Link to="/offers">Priser</Link>
          </li> */}
          <li>
            <Link to="/projects">Projekter</Link>
          </li>
        </Menu>
      </MainNav>
    </MainHeader>
  );
};

export default Header;
