import React, { useState, useEffect } from "react";
import { StyledArticle } from "../Description.Styled";
import Loading from "../../../Components/Partials/Loading";
import Transitions from "../../../Styles/Transition";
import { StyledForm } from "../../../Styles/Form.Styled";
import { IoMdArrowRoundBack } from "react-icons/io";
import {
  useGenerateDescription,
  generateTitleIfNeeded,
} from "./useGenerateDescription";
import { Result } from "./Description.Styled";

const DescriptionGenerator = () => {
  const [formState, setFormState] = useState({
    title: "",
    seedWords: "",
    atmosphere: "",
    temperature: 0.5,
  });

  const {
    isLoading: loadingForDescription,
    generatedDescription,
    error: descriptionError,
    generateDescription,
  } = useGenerateDescription();

  const [result, setResult] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { title, seedWords, temperature } = formState;

    let finalTitle = title;
    if (!title) {
      finalTitle = await generateTitleIfNeeded(seedWords, temperature);
    }

    generateDescription({ title: finalTitle, seedWords, temperature });
  };

  useEffect(() => {
    if (generatedDescription) {
      setResult(true);
    }
  }, [generatedDescription]);

  return (
    <Transitions>
      {!generatedDescription || loadingForDescription || !result ? (
        <StyledArticle>
          <header className='form-introduction'>
            <h1>ONEDAY Viborg</h1>
            <h2>Beskrivelses-generator</h2>
            <p>Udfyld formularen nedenfor for at generere en beskrivelse.</p>
            <br />
          </header>

          <StyledForm onSubmit={handleSubmit}>
            <div className='inputs'>
              {/* Titel/navn */}
              <label htmlFor='title' className='custom-label'>
                Indsæt evt. en titel. Hvis du ikke gør, finder ChatGPT selv på
                én.
              </label>
              <input
                type='text'
                id='title'
                value={formState.title}
                onChange={(e) =>
                  setFormState((prevState) => ({
                    ...prevState,
                    title: e.target.value,
                  }))
                }
                placeholder='Title'
              />

              {/* Stikord */}
              <label>Beskriv øjeblikket kort</label>
              <textarea
                id='seedWords'
                className='custom-label'
                value={formState.seedWords}
                onChange={(e) =>
                  setFormState((prevState) => ({
                    ...prevState,
                    seedWords: e.target.value,
                  }))
                }
                rows='4'
                required
                placeholder='Beskrivelse'></textarea>

              {/* Stemning */}
              <label htmlFor='atmosphere'>Beskriv stemningen</label>
              <input
                type='text'
                id='atmosphere'
                value={formState.atmosphere}
                onChange={(e) =>
                  setFormState((prevState) => ({
                    ...prevState,
                    atmosphere: e.target.value,
                  }))
                }
                required
                placeholder='Stemning'
              />

              {/* Temperatur */}
              <label htmlFor='temperature' className='custom-label'>
                ChatGPT's opfindsomhed. Minimum er 0.1 og maximum er 1.0:
              </label>
              <input
                type='number'
                id='temperature'
                value={formState.temperature}
                onChange={(e) =>
                  setFormState((prevState) => ({
                    ...prevState,
                    temperature: parseFloat(e.target.value),
                  }))
                }
                step='0.1'
                min='0.1'
                max='1.0'
              />

              <button type='submit'>Generér beskrivelse</button>
            </div>
          </StyledForm>
        </StyledArticle>
      ) : (
        <>
          {result && (
            <Result animate={!!generatedDescription}>
              <div className='content-container'>
                <div className='text-container'>
                  <div
                    dangerouslySetInnerHTML={{ __html: generatedDescription }}
                  />
                  <button onClick={() => setResult(false)}>
                    <IoMdArrowRoundBack color='black' size={30} />
                  </button>
                </div>
              </div>
            </Result>
          )}
        </>
      )}
      {loadingForDescription && <Loading loadingText='Genererer tekst..' />}
    </Transitions>
  );
};

export default DescriptionGenerator;
