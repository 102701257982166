import React from "react";
import { TypePage } from "./Types.styled";

const Complex = () => {
  return (
    <TypePage>
      <h4>Complex</h4>
    </TypePage>
  );
};

export default Complex;
