import styled from "styled-components";

export const MainNav = styled.ul`
  display: flex;
  width: 100%;
  height: 10vh;
  z-index: 800;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: ${(props) => (props.shrinkHeader ? "0.2rem" : "1rem")} 4rem;
  transition: padding 500ms ease;
  position: relative;

  img {
    width: 10%;
    position: absolute;
    top: 15px;
    left: 0;
    @media screen and (max-width: 768px) {
      width: 25%;
    }
  }

  .burger {
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1.5em;
    position: relative;

    @media (max-width: 768px) {
      flex-direction: column;
      transition: max-height 0.3 ease-in;
      width: 100%;
      z-index: 50000;
      max-height: ${({ isOpen }) => (isOpen ? "auto" : "0")};
    }
  }

  li {
    list-style: none;
    &:last-of-type {
      margin-left: auto;
    }

    a {
      padding: 0.3em;
      cursor: pointer;
      text-align: center;
      text-decoration: none;
      color: white;
      transition: all 0.3s ease-in;
      font-size: 1.5em;

      &:hover {
        color: #ffa60098;
        cursor: pointer;
        transition: 300ms;
      }

      @media (max-width: 768px) {
        display: block;
        width: 100%;
      }
    }
  }

  @media (max-width: 768px) {
    background-color: black;
    padding: 0;
    display: flex;
    justify-content: flex-end;

    li {
      width: 100%;
    }
  }
`;
